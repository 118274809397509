import React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../../components';
import { defaultTheme } from '../../styles';
import Form from './Form';
import Details from './Details';

const Section = styled.section`
  background: ${defaultTheme.productImgBg};
  padding: 10rem 0 16rem;
`;

const Header = styled(SectionHeader)`
  h1 {
    margin-bottom: 4rem;
  }
  @media (min-width: 900px) {
    h1 {
      margin-bottom: 6rem;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 10rem;
  position: relative;
  align-items: center;
  @media (min-width: 900px) {
    max-width: 828px;
    grid-template-columns: 460px 240px;
    grid-column-gap: 6rem;
    margin: 0 auto;
  }
`;

const Layout = () => {
  //
  return (
    <Section>
      <Header modifiers={['small']}>
        <h1 style={{ textAlign: 'center' }}>Contact VentureMore</h1>
      </Header>
      <Grid>
        <Form />
        <Details />
      </Grid>
    </Section>
  );
};

export default Layout;
