import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { AnimatePresence } from 'framer-motion';
import { contactFormValidation } from '../../utils';
import {
  PrimaryInput as Input,
  TextArea,
  FormLabel,
  PrimaryButton as Button,
  InputErrorMessage,
  Checkbox,
  PrimaryLinkAnchor as Anchor,
} from '../../components';
import ResponseDialog from '../../components/ResponseDialog';

import { breakpoints } from '../../styles';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const errorMessageAnimation = {
  hidden: {
    opacity: 0,
    y: 16,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const FormWrapper = styled.div`
  background: #ffffff;
  padding: 4rem 2rem 4rem;
  border-radius: 8px;

  textarea {
    height: 200px;
    resize: vertical;
  }
  @media (min-width: ${breakpoints.medium}px) {
    width: 460px;
    padding: 4rem 3rem 4rem;
    margin: 0 auto;
    box-shadow: var(--elevation-4);
  }
  @media (min-width: 900px) {
    margin: 0;
  }
`;

const FormElement = styled.div`
  margin-bottom: 2.75rem;
  position: relative;
`;

const ContactForm = () => {
  //
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(QUERY);

  const [response, setResponse] = useState({ successIsShow: false, errorIsShow: false });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
  }, []);

  const Agreement = (
    <Anchor
      href={`${siteUrl}/privacy-policy`}
      target="_blank"
      rel="noopener noreferrer"
      modifiers={['small', 'grayBorder']}
    >
      Agree to our Privacy Policy
    </Anchor>
  );

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          message: '',
          agree: false,
          captcha: false,
        }}
        validationSchema={contactFormValidation}
        validateOnChange={false}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await fetch('https://h29vf4wbg2.execute-api.ap-southeast-1.amazonaws.com/prod/contact', {
              method: 'POST',
              body: JSON.stringify({
                name: values.name,
                email: values.email,
                phone: values.phone,
                message: values.message,
              }),
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
            });

            if (res.status === 200) {
              resetForm();
              setResponse({ ...response, successIsShow: true });
            }
          } catch (error) {
            setResponse({ ...response, errorIsShow: true });
          }
        }}
      >
        {({ values, errors, isSubmitting, handleChange, setErrors, submitForm, validateForm }) => {
          //
          const resetError = field => {
            setErrors({ ...errors, [field]: '' });
          };

          const handleSubmission = async () => {
            const result = await validateForm();
            if (Object.entries(result).length > 0) {
              return null;
            }
            return window.grecaptcha.execute();
          };

          const isBrowser = typeof window !== 'undefined';

          if (isBrowser) {
            window.submitValidatedForm = token => {
              window.grecaptcha.reset();
              if (token) {
                submitForm();
              }
            };
          }

          return (
            <FormWrapper>
              <Form>
                <FormElement>
                  <FormLabel>Name</FormLabel>
                  <Input
                    aria-label="Name"
                    type="text"
                    placeholder="Required"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onFocus={e => {
                      resetError(e.target.name);
                    }}
                  />
                  <AnimatePresence>
                    {errors.name && (
                      <InputErrorMessage
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={errorMessageAnimation}
                        topPosition={-36}
                        arrowPosition={4}
                        onClick={() => resetError('name')}
                      >
                        {errors.name}
                      </InputErrorMessage>
                    )}
                  </AnimatePresence>
                </FormElement>
                <FormElement>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="text"
                    placeholder="Required"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onFocus={e => {
                      resetError(e.target.name);
                    }}
                  />
                  <AnimatePresence>
                    {errors.email && (
                      <InputErrorMessage
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={errorMessageAnimation}
                        topPosition={-36}
                        arrowPosition={4}
                        onClick={() => resetError('email')}
                      >
                        {errors.email}
                      </InputErrorMessage>
                    )}
                  </AnimatePresence>
                </FormElement>
                <FormElement>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onFocus={e => {
                      resetError(e.target.name);
                    }}
                  />
                  {errors.phone}
                </FormElement>
                <div style={{ position: 'relative' }}>
                  <FormLabel>Your message here</FormLabel>
                  <TextArea
                    placeholder="Required"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    onFocus={e => {
                      resetError(e.target.name);
                    }}
                  />
                  <AnimatePresence>
                    {errors.message && (
                      <InputErrorMessage
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={errorMessageAnimation}
                        topPosition={-6}
                        arrowPosition={4}
                        onClick={() => resetError('message')}
                      >
                        {errors.message}
                      </InputErrorMessage>
                    )}
                  </AnimatePresence>
                </div>
                <div style={{ marginTop: '3rem', position: 'relative' }}>
                  <Checkbox
                    labelNode={Agreement}
                    name="agree"
                    value={values.agree}
                    onChange={handleChange}
                    onFocus={e => {
                      resetError(e.target.name);
                    }}
                  />
                  <AnimatePresence>
                    {errors.agree && (
                      <InputErrorMessage
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={errorMessageAnimation}
                        topPosition={-36}
                        arrowPosition={4}
                        onClick={() => resetError('agree')}
                      >
                        {errors.agree}
                      </InputErrorMessage>
                    )}
                  </AnimatePresence>
                </div>
                <div style={{ marginTop: '3rem', marginBottom: '3rem' }} />
                <div
                  className="g-recaptcha"
                  data-sitekey={process.env.GATSBY_ReCAPTCHA_SITEKEY}
                  data-callback="submitValidatedForm"
                  data-size="invisible"
                  // data-badge="inline"
                />
                <Button
                  type="button"
                  modifiers={['secondaryColor', 'full']}
                  disabled={isSubmitting || !values.name || !values.email || !values.message || !values.agree}
                  onClick={handleSubmission}
                >
                  Send Message
                </Button>
              </Form>
            </FormWrapper>
          );
        }}
      </Formik>
      <ResponseDialog
        isShow={response.successIsShow}
        copy={<span>Thank you. We will be in touch soon.</span>}
        close={() => {
          setResponse({ ...response, successIsShow: false });
        }}
      />
      <ResponseDialog
        isShow={response.errorIsShow}
        copy={<span>Sorry. Submission failed. Please try again.</span>}
        close={() => {
          setResponse({ ...response, errorIsShow: false });
        }}
      />
    </>
  );
};

export default ContactForm;
