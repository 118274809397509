/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg height="18px" viewBox="0 0 40 27.53" {...props}>
      <path d="M36,0H4A4,4,0,0,0,0,4L0,28a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4V4A4,4,0,0,0,36,0Zm0,8L20,18,4,8V4L20,14,36,4Z" />
    </svg>
  );
};

export default SVG;
