import React from 'react';
import styled from 'styled-components';
import { SecondaryLinkAnchor as Anchor, SectionItemHeader as Header, SecondaryButtonAnchor } from '../../components';
import { useSiteMetadata } from '../../utils';
import Pin from '../../components/Svg/Pin';
import Phone from '../../components/Svg/Phone';
import Mail from '../../components/Svg/Mail';
import Whatsapp from '../../components/Svg/Whatsapp';

const Wrapper = styled.div`
  padding: 0 1rem 0;
  display: inline-block;
  margin: 0 auto;
  @media (min-width: 900px) {
    margin: 0;
  }
`;

const Segment = styled.div`
  margin-bottom: 3rem;
`;

const ButtonContainer = styled.div`
  margin: 0.75rem 0 0;
`;

const Button = styled(SecondaryButtonAnchor)`
  min-width: 200px;
  height: 50px;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  color: #000000;
`;

const SectionItemHeader = styled(Header)`
  h2 {
    margin-bottom: 0.75rem;
  }
`;

const Datails = () => {
  //
  const { contact } = useSiteMetadata();

  const {
    address: { streetAddress, streetAddressAdditional, addressCountryLong, addressLocality, addressRegion, postalCode },
    googleMap,
    telephone1,
    telephone2,
    email,
    whatsapp,
  } = contact;

  return (
    <Wrapper>
      <Segment>
        <div>
          <SectionItemHeader modifiers={['small']}>
            <h2>Call / Whatsapp</h2>
          </SectionItemHeader>
        </div>
        <div>
          <div>
            <Button modifiers={['raised']} href={`tel:${telephone1.replace(/\s+|-/g, '')}`} aria-label="telephone">
              {telephone1}
              <Phone />
            </Button>
          </div>
          <div>
            <Button modifiers={['raised']} href={`tel:${telephone2.replace(/\s+|-/g, '')}`} aria-label="telephone">
              {telephone2}
              <Phone />
            </Button>
          </div>
          <div>
            <Button
              modifiers={['raised']}
              href={`https://api.whatsapp.com/send?phone=${whatsapp.replace(/\s+|-/g, '')}`}
              aria-label="whatsapp"
            >
              {whatsapp}
              <Whatsapp />
            </Button>
          </div>
        </div>
      </Segment>
      <Segment>
        <div>
          <SectionItemHeader modifiers={['small']}>
            <h2>Our Location</h2>
          </SectionItemHeader>
        </div>
        <div>
          <Anchor
            href={googleMap}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="address"
            modifiers={['medium']}
          >
            {streetAddress},
            <br />
            {streetAddressAdditional},
            <br />
            {`${postalCode}, ${addressLocality}`},
            <br />
            {`${addressRegion}, ${addressCountryLong}`}.
          </Anchor>
          <ButtonContainer>
            <Button
              modifiers={['raised']}
              href={googleMap}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="address"
            >
              GOOGLE MAP
              <Pin />
            </Button>
          </ButtonContainer>
        </div>
      </Segment>
      <div>
        <SectionItemHeader modifiers={['small']}>
          <h2>Email</h2>
        </SectionItemHeader>
      </div>
      <div>
        <Anchor href={`mailto:${email}`} aria-label="email" modifiers={['medium']}>
          {email}
        </Anchor>
        <ButtonContainer>
          <Button modifiers={['raised']} href={`mailto:${email}`} aria-label="email">
            SEND EMAIL <Mail />
          </Button>
        </ButtonContainer>
      </div>
    </Wrapper>
  );
};

export default Datails;
