/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg height="26px" viewBox="0 0 30.93 40" {...props}>
      <path
        fillRule="evenodd"
        fill="#333333"
        d="M15.4,0A15.4,15.4,0,0,1,30.93,15.4c0,9.34-9.9,17.18-15.53,24.6C9.9,32.58,0,24.74,0,15.4A15.37,15.37,0,0,1,15.4,0Zm0,6.87A8.59,8.59,0,1,1,6.87,15.4,8.59,8.59,0,0,1,15.4,6.87Z"
      />
    </svg>
  );
};

export default SVG;
