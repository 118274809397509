/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg height="20px" viewBox="0 0 40 40" {...props}>
      <path d="M5,1C9.76-3.43,17.33,8.18,13.8,11.33l-2.14,2c-2.4,2.14,11,16.78,13.37,14.51l2.14-2c3.41-3.16,14.25,5.42,9.46,9.84l-3.15,2.9C24,47.41-7.89,12.59,1.82,3.89Z" />
    </svg>
  );
};

export default SVG;
