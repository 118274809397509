import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';
import Layout from '../page-components/contact/Layout';

const Contact = ({ location: { pathname } }) => (
  <>
    <SEO title="Contact Us" description="Contact Us" />
    <Layout />
  </>
);

export default Contact;

Contact.propTypes = {
  location: PropTypes.object.isRequired,
};
